import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Home() {
    return (
        <div className="text-center container">
            <div className="mt-5">
                <Button as={Link} to={"/hck"} className="box-shadow main-page-button" variant="outline-primary">Who am I?</Button>
            </div>
            <div className="mt-5">
                <Button as={Link} to={"/art"} className="box-shadow main-page-button" variant="outline-primary">Art</Button>
                <Button as={Link} to={"/software"} className="box-shadow main-page-button" variant="outline-primary">Software</Button>
                <Button as={Link} to={"/race"} className="box-shadow main-page-button" variant="outline-primary">Race</Button>
            </div>
            <div className="row" style={{ marginTop: '10rem' }}>
                <div className="col-4">
                    <img src='/img/icon_writing.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                    <div className="text-matrix-green">
                        Download CV
                    </div>
                </div>
                <div className="col-4">
                    <img src='/img/icon_website.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                    <div className="text-matrix-green">
                        Linkedin
                    </div>
                </div>
                <div className="col-4">
                    <img src='/img/icon_envelope.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                    <div className="text-matrix-green">
                        Email
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
  