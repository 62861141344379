import React from 'react';
import ProjectCard from '../common/ProjectCard';

function Software() {
    const projects = [
        {
            title: "Project Real World (2024)",
            description: "This is a brief description of the first project. It uses React, Node.js, and MongoDB.",
            imgSrc: "/img/project1.png",
            technologies: ["JavaScript", "WebGL", "ThreeJS", "WebSocket", "Redis", "Node.js", "Express.js", "MongoDB"]
        },
        {
            title: "Community Map (2021)",
            description: "This is a brief description of the second project. It involves Python and Flask.",
            imgSrc: "/img/project2.png",
            technologies: ["Angular", "NodeJS", "ExpressJS", "MongoDB", "Google Maps API"]
        },
        {
            title: "Leprikon (2019)",
            description: "This project focuses on front-end development using Angular.",
            imgSrc: "/img/project3.png",
            technologies: ["Angular"]
        },
        {
            title: "Yeni Soru (New Question) (2020)",
            description: "This project focuses on front-end development using Angular.",
            imgSrc: "/img/project3.png",
            technologies: ["Angular"]
        }
    ];

    return (
        <div className="container mt-5">
            <h1 className="text-white">Projects</h1>
            <div className="text-white">Beta Beta Beta</div>
            <div>
                {projects.map((project, index) => (
                    <div className="mt-4" key={index}>
                        <ProjectCard
                            key={index}
                            title={project.title}
                            description={project.description}
                            imgSrc={project.imgSrc}
                            technologies={project.technologies}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Software;
