import React from 'react';
import {Link} from "react-router-dom";

function Header() {
    return (
        <div className="text-center container">
            <div className="mt-5">
                <Link to="/">
                    <img src='/img/text_official.png' alt="GIF Description"
                         style={{width: '300px', height: 'auto', maxWidth: '50%'}}/>
                </Link>
            </div>
            <div className="mt-5">
                <Link to="/">
                    <img src='/img/text_hck.png' alt="GIF Description" style={{maxWidth: '100%'}}/>
                </Link>
            </div>
        </div>
    );
}

export default Header;
