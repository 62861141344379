import { Link } from "react-router-dom";

function Art() {
    return (
        <div className="text-center container">
            <div style={{marginTop: '30vh'}}>
                <div>
                    <img src='/img/text_coming_soon.png' alt="GIF Description" style={{width: '300px', height: 'auto', maxWidth: '50%'}}/>
                </div>
                <div className="mt-5">
                    <img src='/img/icon_homepage.png' alt="GIF Description" style={{width: '50px', height: 'auto'}}/>
                    <div className="text-matrix-green"><Link to="/">Go To Homepage</Link></div>
                </div>
            </div>
        </div>
    );
}

export default Art;
  