import React from 'react';
import './styles/main.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Hck from "./components/pages/Hck";
import Art from "./components/pages/Art";
import Software from "./components/pages/Software";
import Race from "./components/pages/Race";
import Header from "./components/common/Header";

function App() {
  return (
      <Router>
          <Header />
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/hck" element={<Hck />} />
              <Route path="/art" element={<Art />} />
              <Route path="/software" element={<Software />} />
              <Route path="/race" element={<Race />} />
          </Routes>
      </Router>
  );
}

export default App;
