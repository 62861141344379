import React from 'react';
import { Card } from 'react-bootstrap';
import './ProjectCard.scss'
import {Link} from "react-router-dom";

const ProjectCard = ({ title, description, technologies, imageUrl }) => {
    return (
        <Card>
            <Link to="/">
                <Card.Body className="text-primary">
                    <div className="row">
                        <div className="col-auto">
                            <div className="bg-secondary" style={{width: '284px', height: '160px'}}></div>
                            {/*<Card.Img variant="top" src={imageUrl} alt="img" style={{width: '160px', height: '160px'}}/>*/}
                        </div>
                        <div className="col">
                            <Card.Title className="text-white">{title}</Card.Title>
                            <Card.Text>
                                {description}
                                {description}
                                {description}
                                {description}
                                {description}
                            </Card.Text>
                            <div>
                                {technologies.map((tech, index) => (
                                    <span key={index} className="badge bg-danger me-1">
                                        {tech}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Link>


        </Card>
    );
};

export default ProjectCard;
